import React from "react";
import RadCard from "components/RADCard";
import "styles/app.css";

export default () => (
  <div className="app">
    <header className="app-header">
      <RadCard
        title="RAD GitHub"
        description="Rakuten Advertising Developers GitHub"
        link="https://github.com/Rakuten-Advertising-Developers"
      />

      <RadCard
        title="iOS Attribution SDK Docs"
        description="Rakuten Attribution iOS SDK Documentation"
        link="doc/attribution/ios"
      />

      <RadCard
        title="Android Attribution SDK Docs"
        description="Rakuten Attribution Android SDK Documentation"
        link="doc/attribution/android"
      />
    </header>
  </div>
);
