import { useState, useEffect } from "react";

export default (url: string) => {
  const [data, setData] = useState("");

  useEffect(() => {
    (async () => {
      const response = await fetch(url);
      const text = await response.text();
      setData(text);
    })();
  }, [url]);

  return data;
};
