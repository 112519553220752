import React, { useState, useEffect } from "react";
import Fingerprint2 from "fingerprintjs2";

export default () => {
  const [attrData, setAttrData] = useState({});

  function fingerDevice() {
    setTimeout(function () {
      var options = {
        excludes: {
          fonts: true,
          webgl: true,
          plugins: true,
          enumerateDevices: true,
          canvas: true,
        },
      };
      Fingerprint2.get(options, async function (components: Array<object>) {
        const finger = components.reduce((acc: any, cur: any) => {
          console.log(cur.value);
          acc[cur.key] = cur.value;
          return acc;
        }, {});

        const ipDataPromise = await fetch("https://api.ipify.org?format=json");
        const ipData = await ipDataPromise.text();
        const ip = JSON.parse(ipData).ip;

        finger.ip = ip;

        console.log(JSON.stringify(finger));

        if ((window as any).webkit) {
          (window as any).webkit.messageHandlers.finger.postMessage(
            JSON.stringify(finger)
          );
        } else if ((window as any).finger) {
          (window as any).finger.postMessage(JSON.stringify(finger));
        }

        setAttrData(finger);
      });
    }, 500);
  }

  useEffect(() => {
    fingerDevice();
  }, []);

  return <pre>{`${JSON.stringify(attrData, null, 2)}`}</pre>;
};
